import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiChevronRight, BiHomeAlt, BiBarChartAlt2, BiBell, BiLogOut, BiTask } from "react-icons/bi";
import { Usercontext } from "../../Store/UserContext";
import { FaRegAddressCard } from "react-icons/fa";
import { FaIdCard } from "react-icons/fa6";
import { MdHowToVote } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoPersonCircleOutline } from "react-icons/io5";

const Sidebar = () => {
  const [isClosed, setIsClosed] = useState(false); // Sidebar open/close state
  const [isDarkMode, setIsDarkMode] = useState(false); // Dark mode toggle
  const location = useLocation(); // Get the current location
  const { user, loading, error } = useContext(Usercontext);

  const toggleSidebar = () => {
    setIsClosed(!isClosed);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const currentPath = location.pathname;

  return (
    <div className={`${isDarkMode ? "dark" : ""} flex`}>
      <nav
        className={`bg-[#075985] mt-3 h-screen fixed text-white dark:bg-ocean-800 scrollbar-none transition-all duration-300 
        ${isClosed ? "w-20" : "w-64"}  overflow-auto p-4 pb-20`}
      >
        <header className="relative flex items-center flex-col">
          {/* Toggle button */}
          <button
            onClick={toggleSidebar}
            className={`bg-[#2e83b1] text-white p-2  rounded-full absolute top-4 transition-all duration-300
              ${isClosed ? "right-[-12px]" : "right-[-24px]"}`}
          >
            <BiChevronRight
              className={`text-2xl  transform transition-transform duration-300 ${
                isClosed ? "" : "rotate-180"
              }`}
            />
          </button>

          {/* User name and Edit button */}
          <div className={`mt-10 flex flex-col items-center ${isClosed ? "hidden" : ""}`}>
          {user && (
      <>
        {/* Profile image */}
        
        <IoPersonCircleOutline className="w-14 h-14" />
        {/* <img
          src={user.profileImage || "/default-profile.png"} // Fallback to default image if no profile image exists
          alt="User Profile"
          className="w-16 h-16 rounded-full object-cover border-2 border-white"
        /> */}

        {/* User name */}
        <span className="mt-2 text-lg font-medium text-ocean-500 dark:text-ocean-400">
          {user.name}
        </span>
      </>
    )}
          </div>
        </header>

        <div className={` ${isClosed ? "mt-20" : "mt-6"}`}>
          <ul className="space-y-4">
         
            <li>
              <Link
                to="/dashbord/serviceslist"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/serviceslist" ? "bg-sky-900" : ""
                }`}
              >
                <BiBarChartAlt2 className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Services List</span>
                )}
              </Link>
            </li>
            {user.role === 'customer' &&
            <li>
              <Link
                to="/dashbord/apliedCertificates"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/apliedCertificates" ? "bg-sky-900" : ""
                }`}
              >
                <BiHomeAlt className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">
                    Applied Certificate
                  </span>
                )}
              </Link>
            </li>
            }
            {user.role === 'employee' &&
            <li>
              <Link
                to="/dashbord/allcertificate"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/allCertificate" ? "bg-sky-900" : ""
                }`}
              >
                <BiBarChartAlt2 className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">
                    All Certificates
                  </span>
                )}
              </Link>
            </li>
            
            }
            {user.role === 'employee' &&
            <li>
              <Link
                to="/dashbord/task"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/task" ? "bg-sky-900" : ""
                }`}
              >
                <BiTask className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">
                    Task
                  </span>
                )}
              </Link>
            </li>
            
            }          
            <li>
              <Link
                to="/dashbord/notifications"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/notifications" ? "bg-sky-900" : ""
                }`}
              >
                <BiBell className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Notifications</span>
                )}
              </Link>
            </li>
            <hr className="bg-white h-1 rounded-lg mb-2" />
            <li>
              <Link
                to="/dashbord/aadhar"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/aadhar" ? "bg-sky-900" : ""
                }`}
              >
                <FaRegAddressCard className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Aadhar Services</span>
                )}
              </Link>
            </li>
            <li>
              <Link
                to="/dashbord/pan"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/pan" ? "bg-sky-900" : ""
                }`}
              >
                <FaIdCard className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Pan Card Services</span>
                )}
              </Link>
            </li>
            <li>
              <Link
                to="/dashbord/election"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/election" ? "bg-sky-900" : ""
                }`}
              >
              <MdHowToVote className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100">Election Card</span>
                )}
              </Link>
            </li>
            <li>
              <Link
                to="/dashbord/ration"
                className={`flex items-center space-x-4 hover:text-white hover:bg-sky-900 dark:hover:bg-ocean-700 p-2 rounded-md ${
                  currentPath === "/dashbord/ration" ? "bg-sky-900" : ""
                }`}
              >
               <FaPeopleGroup  className="text-xl text-ocean-600 dark:text-ocean-400" />
                {!isClosed && (
                  <span className="text-md font-medium text-ocean-800 dark:text-ocean-100"> Ration Card</span>
                )}
              </Link>
            </li> 
           
          </ul>
        </div>
      </nav>
      {/* Main content area adjusted based on sidebar width */}
      <div
        className={`flex-1 transition-all duration-300 ${isClosed ? "lg:ml-20" : "lg:ml-64"}`}
      >
        {/* Render your main content here */}
      </div>
    </div>
  );
};

export default Sidebar;


