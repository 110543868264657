import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#075985] text-white">
      <div className="w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
        {/* Main Footer Sections */}
        <div className="flex flex-col md:flex-row md:flex-wrap md:space-x-8 space-y-8 md:space-y-0">
          {/* YSL Web Section */}
          <div className="flex-1">
            <h2 className="text-xl font-bold">YEL SEVA</h2>
            <p className="text-sm mt-2">
              As part of the Yahshua Group, our goal is to bring every business
              online, offering cutting-edge digital solutions that elevate your
              brand. We believe in delivering high-quality projects at competitive
              prices, ensuring customer satisfaction and lasting partnerships.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="flex flex-col">
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li className="hover:underline">
                <a href="/about">About Us</a>
              </li>
              <li className="hover:underline">
                <a href="/services">Services</a>
              </li>
              <li className="hover:underline">
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li className="hover:underline">
                <a href="/terms">Terms of Service</a>
              </li>
            </ul>
          </div>

          {/* Certificate Services Section */}
          <div className="flex-1 md:w-1/2 lg:w-1/4">
            <h3 className="text-lg font-bold mb-4">Certificate Services</h3>
            <div className="flex flex-wrap">
              <ul className="flex-1 space-y-2">
                <li className="hover:underline whitespace-nowrap">
                  <a href="/birth-certificate">Birth Certificate</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/death-certificate">Death Certificate</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/income-certificate">Income Certificate</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/residence-certificate">Residence Certificate</a>
                </li>
              </ul>
              <ul className="flex-1 space-y-2">
                <li className="hover:underline whitespace-nowrap">
                  <a href="/caste-certificate">Caste Certificate</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/marriage-certificate">Marriage Certificate</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/driving-license">Driving License</a>
                </li>
                <li className="hover:underline whitespace-nowrap">
                  <a href="/passport">Passport Services</a>
                </li>
              </ul>
            </div>
          </div>

          {/* Contact Information Section */}
          <div className="flex-1 md:w-1/2 lg:w-1/4">
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>
            <p className="text-sm"><span className=" font-bold">Address :</span> Pune, Maharashtra, India</p>
            <p className="text-sm"><span className=" font-bold">Phone :</span> +91 8850115960</p>
            <p className="text-sm"><span className=" font-bold">Email :</span> yahshuaenterprises@gmail.com</p>

            {/* Social Media Links */}
            <div className="mt-4">
              <h4 className="text-sm font-bold">Follow Us</h4>
              <div className="flex space-x-4 mt-2">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xl hover:text-orange-400"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xl hover:text-orange-400"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xl hover:text-orange-400"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xl hover:text-orange-400"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>

          {/* Subscription Section */}
          <div className="flex-1 order-last md:order-4">
            <h3 className="text-lg font-bold mb-4">Subscribe to Our Newsletter</h3>
            <p className="text-sm mb-2">
              Get the latest updates and offers directly in your inbox.
            </p>
            <form>
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 mb-3 rounded-md text-black focus:outline-none"
              />
              <button
                type="submit"
                className="w-full bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 mt-4 pt-2 text-center">
          <p className="text-sm">
            Copyright &#169; {new Date().getFullYear()} Yahshua Software Ltd, All
            Rights Reserved.
          </p>
          <p className="text-sm">Best viewed on Chrome, Firefox, or Safari.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
