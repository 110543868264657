import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryApi from '../../common/Apis';
import documentsList from './documentsList';

const AddDepartmentForm = () => {
  const [departmentName, setDepartmentName] = useState('');
  const [certificates, setCertificates] = useState([
    { name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1  }
  ]);

  const handleDepartmentChange = (e) => {
    setDepartmentName(e.target.value);
  };

  const handleCertificateChange = (index, field, value) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index][field] = value;
    setCertificates(updatedCertificates);
  };

  const addNewCertificate = () => {
    setCertificates([...certificates, { name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1 }]);
  };

  const resetForm = () => {
    setDepartmentName('');
    setCertificates([{ name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1  }]);
  };

  const addNewDepartment = async () => {
    const formattedCertificates = certificates.map(cert => ({
      name: cert.name,
      description: cert.description,
      amount: cert.amount,
      proofOfIdentity: cert.proofOfIdentity ? cert.proofOfIdentity.map(option => option.value) : [],
      proofOfAddress: cert.proofOfAddress ? cert.proofOfAddress.map(option => option.value) : [],
      proofOfIncome:cert.proofOfIncome ? cert.proofOfIncome.map(option => option.value) : [],
      ageProof:cert.ageProof? cert.ageProof.map(option => option.value) : [],
      otherDocuments:cert.otherDocuments ? cert.otherDocuments.map(option => option.value) : [],
      mandatoryDocuments:cert.mandatoryDocuments ? cert.mandatoryDocuments.map(option => option.value) : [],



      proofOfAddressRequiredCount: cert.proofOfAddressCount,
      proofOfIdentityRequiredCount : cert.proofOfIdentityCount,
      proofOfIncomeCount:cert.proofOfIncomeCount,
      ageProofCount:cert.ageProofCount,
      otherDocumentsCount:cert.otherDocumentsCount,
      mandatoryDocumentscount:cert.mandatoryDocumentscount 
    }));

    console.log(formattedCertificates)

    try {
      console.log(SummaryApi.addDepartment.url)
      const response = await axios.post(SummaryApi.addDepartment.url, { name: departmentName, certificates: formattedCertificates });
      toast.success(response.data.message);
      resetForm(); // Reset form after successful submission
    } catch (error) {
      console.error('Error adding department:', error);
      toast.error('Failed to add department');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 scrollbar-none bg-white shadow-md rounded-lg mt-6">
      <ToastContainer />
      <h2 className="text-xl font-bold text-center mb-3">Add New Department</h2>
      <input
        type="text"
        className="block w-full p-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={departmentName}
        onChange={handleDepartmentChange}
        placeholder="Department Name"
      />
      <h3 className="text-lg font-semibold mb-2">Certificates</h3>

      {certificates.map((certificate, index) => (
        <div key={index} className="mb-4">
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Certificate Name</label>
            <input
              type="text"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.name}
              onChange={(e) => handleCertificateChange(index, 'name', e.target.value)}
              placeholder="Certificate Name"
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <input
              type="text"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.description}
              onChange={(e) => handleCertificateChange(index, 'description', e.target.value)}
              placeholder="Description"
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.amount}
              onChange={(e) => handleCertificateChange(index, 'amount', e.target.value)}
              placeholder="Amount"
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Identity</label>
            <Select
              options={documentsList}
              value={certificate.proofOfIdentity}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfIdentity', selectedOption)}
              className="w-full"
              placeholder="Select Proof of Identity"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Identity Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfIdentityCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfIdentityCount', e.target.value)}
              placeholder="Proof of Identity Count"
              min="0"
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Address</label>
            <Select
              options={documentsList}
              value={certificate.proofOfAddress}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfAddress', selectedOption)}
              className="w-full"
              placeholder="Select Proof of Address"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Address Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfAddressCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfAddressCount', e.target.value)}
              placeholder="Proof of Address Count"
              min="0"
            />
          </div>


          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">mandatory Documents</label>
            <Select
              options={documentsList}
              value={certificate.mandatoryDocuments}
              onChange={(selectedOption) => handleCertificateChange(index, 'mandatoryDocuments', selectedOption)}
              className="w-full"
              placeholder="Select Proof of mandatory Documents"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of mandatory Documents count </label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.mandatoryDocumentscount}
              onChange={(e) => handleCertificateChange(index, 'mandatoryDocumentscount', e.target.value)}
              placeholder="Proof of mandatory Documents count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700"> other Documents</label>
            <Select
              options={documentsList}
              value={certificate.otherDocuments}
              onChange={(selectedOption) => handleCertificateChange(index, 'otherDocuments', selectedOption)}
              className="w-full"
              placeholder="Select Proof of  other Documents"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of  other Documents Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.otherDocumentsCount}
              onChange={(e) => handleCertificateChange(index, 'otherDocumentsCount', e.target.value)}
              placeholder="Proof of  other Documents Count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of age</label>
            <Select
              options={documentsList}
              value={certificate.ageProof}
              onChange={(selectedOption) => handleCertificateChange(index, 'ageProof', selectedOption)}
              className="w-full"
              placeholder="Select Proof of age Proof"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of age Proof Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.ageProofCount}
              onChange={(e) => handleCertificateChange(index, 'ageProofCount', e.target.value)}
              placeholder="Proof of age Proof Count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of income</label>
            <Select
              options={documentsList}
              value={certificate.proofOfIncome}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfIncome', selectedOption)}
              className="w-full"
              placeholder="Select Proof of income proof"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of proof Of Income Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfIncomeCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfIncomeCount', e.target.value)}
              placeholder="Proof of proof Of Income Count"
              min="0"
            />
          </div>
        </div>
      ))}

      <button
        onClick={addNewCertificate}
        className="w-full bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-3"
      >
        Add Another Certificate
      </button>

      <button
        onClick={addNewDepartment}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Add Department
      </button>
    </div>
  );
};

export default AddDepartmentForm;
