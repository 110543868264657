import React, { useState, useContext } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserNavbar from "../Component/Navbar/UserNavbar";
import { Usercontext } from "../Store/UserContext";
import SummaryApi from "../common/Apis";

const ProfilePage = () => {
  const { user, setUser } = useContext(Usercontext);
  const [fullName, setFullName] = useState(user.name || "");
  const [mobileNumber, setMobileNumber] = useState(user.phoneNo);
  const [emailId, setEmailId] = useState(user.email);
  const [address, setAddress] = useState(user.address);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${SummaryApi.UpdateUser.url}/${user._id}`,
        {
          name: fullName,
          number: mobileNumber,
          email: emailId,
          address: address,
        }
      );
      setUser(response.data.user);
      toast.success("Profile has been successfully updated!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleDeleteAccount = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleSendRequest = async () => {
    try {
      const response = await axios.post("http://localhost:5000/send-request", {
        fullName,
        emailId,
      });
      setIsModalOpen(false); // Close the modal after sending request
      toast.success(response.data.message || "Request sent successfully!");
    } catch (error) {
      console.error("Error sending request:", error);
      toast.error("Failed to send request. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen w-full">
      <UserNavbar />
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex items-center justify-center mt-5 h-full">
        <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg w-full max-w-5xl">
          <div className="w-full md:w-1/3 p-6 flex items-center justify-center bg-gray-100">
            <img
              src="profile.jpg" // Replace with your image URL
              alt="Profile"
              className="w-full h-auto rounded-lg shadow-md object-cover"
            />
          </div>
          <div className="w-full md:w-2/3 p-8">
            <h2 className="text-2xl font-semibold text-center mb-6">
              Profile Information
            </h2>
            <div className="mb-4">
              <label className="text-sm text-gray-500 font-bold">Full Name:</label>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Enter Full Name"
              />
            </div>
            <div className="mb-4">
              <label className="text-sm text-gray-500 font-bold">
                Mobile Number:
              </label>
              <input
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Enter phone number"
              />
            </div>
            <div className="mb-4">
              <label className="text-sm text-gray-500 font-bold">Email ID:</label>
              <input
                type="email"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Enter email ID"
              />
            </div>
            <div className="mb-4">
              <label className="text-sm text-gray-500 font-bold">Address:</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Enter Address"
                rows="4"
              />
            </div>
            <div className="flex justify-center gap-2">
              <button
                onClick={handleSaveProfile}
                className="bg-[#075985] text-white px-6 py-2 rounded-lg hover:bg-[#32779b]"
              >
                Update Profile
              </button>
              <button
                onClick={handleDeleteAccount}
                className="hover:bg-[#db4024] text-white px-6 py-2 rounded-lg bg-[#f31e1e]"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Deletion Request */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-lg">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">
            Delete Account Request
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            Are you sure you want to delete your account? This action cannot be undone.
          </p>
      
          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <div className="mb-3">
              <label className="text-sm text-gray-600 font-bold">Full Name:</label>
              <p className="text-lg text-gray-800 mt-1">{fullName}</p>
            </div>
            <div className="mb-3">
              <label className="text-sm text-gray-600 font-bold">Email ID:</label>
              <p className="text-lg text-gray-800 mt-1">{emailId}</p>
            </div>
          </div>
      
          <div className="mb-6">
            <label className="text-sm text-gray-600 font-bold">
              Reason for Deletion:
            </label>
            <textarea
              className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-400"
              placeholder="Enter your reason"
              rows="4"
            />
          </div>
      
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-5 py-2 text-gray-700 bg-gray-200 rounded-lg shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleSendRequest}
              className="px-5 py-2 text-white bg-red-600 rounded-lg shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
      
      )}
    </div>
  );
};

export default ProfilePage;
