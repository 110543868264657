import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryApi from "../common/Apis";
import { FaEyeSlash ,FaEye } from "react-icons/fa6";


const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await axios.post(SummaryApi.signUp.url, {
        name ,
        email,
        password,
        phoneNo,
      });

      toast.success("Signup successful! Please login.", {
        position: "top-right",
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data, { position: "top-right", autoClose: 3000 });
      } else {
        toast.error("An error occurred during signup", { position: "top-right", autoClose: 3000 });
      }
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="h-[80%] mt-5 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-8">
        <h2 className="text-xl font-semibold text-center mb-6">Signup</h2>
        <form onSubmit={handleSignup}>
          <input
            type="text"
            placeholder="Enter your name"
            className="w-full h-10 px-4 mb-4 text-md border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Enter your email"
            className="w-full h-10 px-4 mb-4 text-md border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Enter your phone number"
            className="w-full h-10 px-4 mb-4 text-md border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
            value={phoneNo}
            // onChange={(e) => setPhoneNo(e.target.value)}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 10) {
                setPhoneNo(value); // Allow updating only if length is 12 or less
              }
            }}
            required
            onBlur={() => {
              if (phoneNo.length > 10) {
                toast.error("Plz Enter Valid No", { position: "top-right", autoClose: 4000 });
              }
            }}
          />
          <div className="relative mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className="w-full h-10 px-4 text-md border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="relative mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirm your password"
              className="w-full h-10 px-4 text-md border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <input
            type="submit"
            className="w-full h-10 bg-[#6279B8] text-white text-md font-medium rounded-md mt-6 cursor-pointer hover:bg-[#006653] transition-colors"
            value="Signup"
          />
        </form>
        <div className="login text-center mt-6">
          <span>
            Already have an account?{" "}
            <Link
              to="/login"
              className="text-[#6279B8] cursor-pointer hover:underline"
            >
              Login
            </Link>
          </span>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Signup;
