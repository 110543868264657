import React from "react";
import ServicesAvailable from "../Component/Services/ServicesAvailable";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center bg-gray-200 overflow-x-hidden">
      {/* Fixed Navbar */}
      <div className="fixed w-full z-10">
        <Navbar />
      </div>

      {/* Main Content Wrapper */}
      <div className="flex-grow flex flex-col mb-6 sm:mb-10 mt-[4.5rem] sm:mt-[5rem] lg:mt-[6rem] justify-center items-center">
        {/* Content in Row on Large Screens */}
        <div className="flex flex-col lg:flex-row w-full items-center lg:justify-center mt-5 gap-6 px-4 sm:px-8 lg:px-16">
          {/* ServicesAvailable Component */}
          <div className="w-full lg:w-3/5 flex justify-center items-center">
            <ServicesAvailable />
          </div>

          {/* Login/Signup Component */}
          <div className="w-full lg:w-1/3 flex justify-center items-center">
            <div className="w-full max-w-sm">
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
