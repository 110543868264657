import React, { useState, useEffect } from 'react';
import SummaryApi from '../../common/Apis';
import axios from 'axios';

const AllUserCertificates = ({ isClosed }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [updating, setUpdating] = useState(false); // State to manage loading state during updates

  const statusOptions = ['pending', 'approved', 'rejected'];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(SummaryApi.users.url, {
            method: SummaryApi.users.method,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error ${response.status}: ${errorText}`);
          }

          const userData = await response.json();
          setAllUsers(userData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    } else {
      setError('No token found, please login.');
      setLoading(false);
    }
  }, []);

  const filteredUsers = allUsers
    .filter(
      (user) =>
        (user.certificatesApplied && user.certificatesApplied.length > 0) &&
        (user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  const handleStatusChange = async (userId, certificateId, newStatus) => {
    const url = `${SummaryApi.users.url}/${userId}/certificates/${certificateId}/status`;
    setUpdating(true);

    try {
      const response = await axios.put(url, { status: newStatus });

      if (response.status === 200) {
        setAllUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId
              ? {
                ...user,
                certificatesApplied: user.certificatesApplied.map((cert) =>
                  cert._id === certificateId
                    ? { ...cert, status: newStatus }
                    : cert
                ),
              }
              : user
          )
        );
        console.log('Status updated successfully!');
      }
    } catch (error) {
      console.error('Error updating certificate status:', error);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mt-4">
      <h1 className="text-2xl font-semibold">All Certificates from Each User</h1>
      <input
        type="text"
        placeholder="Search by name or email"
        className="mb-4 px-4 py-2 border-gray-600 border rounded w-full"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Certificates Table */}
      <table className="min-w-full border-separate border-spacing-0 border rounded-lg overflow-hidden border-gray-900 mt-5">
        <thead>
          <tr className='rounded-lg'>
            <th className="px-4 py-2 border bg-[#075985] text-white rounded-tl-lg">User Name</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Email</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Certificate ID</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Certificate Name</th>
            <th className="px-4 py-2 border bg-[#075985] text-white">Uploaded Documents</th>
            {/* <th className="border px-2 py-1  border-gray-600  bg-[#075985] text-white">Payment Status</th> */}
            <th className="px-4 py-2 border bg-[#075985] text-white">Status</th>
            <th className="px-4 py-2 border  bg-[#075985] text-white rounded-tr-lg">Application Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) =>
              user.certificatesApplied.map((certificate) => (
                <tr key={certificate._id}>
                  <td className="border bg-white px-2 py-1">{user.name}</td>
                  <td className="border  bg-white px-2 py-1">{user.email}</td>
                  <td className="border  bg-white px-2 py-1">{certificate._id}</td>
                  <td className="border bg-white  px-2 py-1">{certificate.certificateName}</td>
                  <td className="border bg-white px-2 py-1">
                    {certificate.uploadedDocuments.map((doc, idx) => (
                      <div key={idx}>
                        {idx + 1}.{' '}
                        <a href={doc.path} target="_blank" rel="noopener noreferrer">
                          {doc.filename}
                        </a>
                      </div>
                    ))}
                  </td>
                  <td className="border  bg-white px-2 py-1">
                    <select
                      value={certificate.status}
                      onChange={(e) =>
                        handleStatusChange(user._id, certificate._id, e.target.value)
                      }
                      className="border  p-2 rounded-md"
                      disabled={updating}
                    >
                      {statusOptions.map((status) => (
                        <option key={status} value={status}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border bg-white px-2 py-1">
                    {new Date(certificate.applicationDate).toLocaleDateString()}
                  </td>
                </tr>
              ))
            )
          ) : (
            <tr>
              <td colSpan="7" className="border px-2 py-1 text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllUserCertificates;
