import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryApi from '../../common/Apis';

import documentsList from './documentsList';

// Proof of Identity Options
const proofOfIdentityOptions = [
  { value: 'PAN Card', label: 'PAN Card' },
  { value: 'Photo', label: 'Photo' },
  { value: 'Signature', label: 'Signature' },
  { value: 'Old Pan Card', label: 'Old Pan Card' },
  { value: 'Passport', label: 'Passport' },
  { value: 'RSBY Card', label: 'RSBY Card' },
  { value: 'Aadhaar Card', label: 'Aadhaar Card' },
  { value: 'Voter ID Card', label: 'Voter ID Card' },
  { value: 'MNREGA Job Card', label: 'MNREGA Job Card' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'Photo of Applicant', label: 'Photo of Applicant' },
  { value: 'Signature of Applicant', label: 'Signature of Applicant' },
  { value: 'Identity card issued by Govt or Semi Govt organizations', label: 'Identity card issued by Govt or Semi Govt organizations' }
];

// Proof of Address Options
const proofOfAddressOptions = [
  { value: 'Passport', label: 'Passport' },
  { value: 'Water Bill', label: 'Water Bill' },
  { value: 'Ration Card', label: 'Ration Card' },
  { value: 'Aadhaar Card', label: 'Aadhaar Card' },
  { value: 'Voter ID Card', label: 'Voter ID Card' },
  { value: 'Telephone Bill', label: 'Telephone Bill' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'Electricity Bill', label: 'Electricity Bill' },
  { value: 'Property Tax Receipt', label: 'Property Tax Receipt' },
  { value: 'Extracts of 7/12 and 8 A/Rent Receipt', label: 'Extracts of 7/12 and 8 A/Rent Receipt' }
];
const ageproofOptions=[
{value:'Birth Certificate', label:'Birth Certificate'},
{value:'Bonafide Certificate', label:'Bonafide Certificate'},
{value:'School Leaving Certificate',label:'School Leaving Certificate'},
{value:'Extract from primary school entry',label:'Extract from primary school entry'},
{value:'Aadhaar of Parent/Guardian',label:'Aadhaar of Parent/Guardian'},
{value:'Voter ID Card',label:'Voter ID Card'}
];

const proofOfIncomeOptions=[
  { value: 'Aadhaar Card', label: 'Aadhaar Card' }
]

const AddCertificateForm = () => {
  const [departmentName, setDepartmentName] = useState('');
  const [departments, setDepartments] = useState([]);
  const [certificates, setCertificates] = useState([{ name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1 }]);
  const [message, setMessage] = useState('');

  const addCertificatesToDepartment = async (departmentName, formattedCertificates) => {
    console.log(formattedCertificates)
    try {
      const response = await axios.post(SummaryApi.addCertificates.url, {
        departmentName,
        newCertificates: formattedCertificates,
      });
      setMessage(response.data.message);
      toast.success(response.data.message);
      resetForm();
    } catch (error) {
      console.error('Error adding certificates:', error);
      setMessage('Failed to add certificates');
      toast.error('Failed to add department');
    }
  };

  const handleDepartmentChange = (e) => {
    setDepartmentName(e.target.value);
  };

  const handleCertificateChange = (index, field, value) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index][field] = value;
    setCertificates(updatedCertificates);
  };

  const addNewCertificate = () => {
    setCertificates([...certificates, { name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1 }]);
  };

  const addNewDepartment = () => {
    const formattedCertificates = certificates.map(cert => ({
      ...cert,
      proofOfIdentity: cert.proofOfIdentity.map(option => option.value),
      proofOfAddress: cert.proofOfAddress.map(option => option.value),
      proofOfIncome:cert.proofOfIncome ? cert.proofOfIncome.map(option => option.value) : [],
      ageProof:cert.ageProof? cert.ageProof.map(option => option.value) : [],
      otherDocuments:cert.otherDocuments ? cert.otherDocuments.map(option => option.value) : [],
      mandatoryDocuments:cert.mandatoryDocuments ? cert.mandatoryDocuments.map(option => option.value) : [],
      proofOfAddressRequiredCount: cert.proofOfAddressCount,
      proofOfIdentityRequiredCount : cert.proofOfIdentityCount,
      proofOfIncomeCount:cert.proofOfIncomeCount,
      ageProofCount:cert.ageProofCount,
      otherDocumentsCount:cert.otherDocumentsCount,
      mandatoryDocumentscount:cert.mandatoryDocumentscount 
    }));
    addCertificatesToDepartment(departmentName, formattedCertificates);
  };

  const resetForm = () => {
    setDepartmentName('');
    setCertificates([{ name: '', description: '', amount: '', proofOfIdentity: null, proofOfAddress: null,proofOfIncome:null,ageProof:null,otherDocuments:null,mandatoryDocuments:null, proofOfIdentityCount: 1, proofOfAddressCount: 1,proofOfIncomeCount:1,ageProofCount:1,otherDocumentsCount:1,mandatoryDocumentscount:1 }]);
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch(SummaryApi.addDepartment.url);
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-lg mt-6">
      <ToastContainer />
      <h2 className="text-xl font-bold text-center mb-2">Add New Certificates</h2>
      <select
        className="block w-full p-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-md"
        value={departmentName}
        onChange={handleDepartmentChange}
      >
        <option value="" disabled>
          Select Department
        </option>
        {departments.map((department) => (
          <option key={department._id} value={department.name}>
            {department.name}
          </option>
        ))}
      </select>
      <h3 className="text-lg font-semibold mb-1">Certificates</h3>

      {certificates.map((certificate, index) => (
        <div key={index} className="mb-4">
          {/* Certificate Name */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Certificate Name</label>
            <input
              type="text"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              value={certificate.name}
              onChange={(e) => handleCertificateChange(index, 'name', e.target.value)}
              placeholder="Certificate Name"
            />
          </div>
           {/* Description */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <input
              type="text"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              value={certificate.description}
              onChange={(e) => handleCertificateChange(index, 'description', e.target.value)}
              placeholder="Description"
            />
          </div>
           {/* Amount */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              value={certificate.amount}
              onChange={(e) => handleCertificateChange(index, 'amount', e.target.value)}
              placeholder="Amount"
            />
          </div>
          {/* Proof of Identity */}
     
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Identity</label>
            <Select
              options={documentsList}
              value={certificate.proofOfIdentity}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfIdentity', selectedOption)}
              className="w-full"
              placeholder="Select Proof of Identity"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Identity Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfIdentityCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfIdentityCount', e.target.value)}
              placeholder="Proof of Identity Count"
              min="0"
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Address</label>
            <Select
              options={documentsList}
              value={certificate.proofOfAddress}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfAddress', selectedOption)}
              className="w-full"
              placeholder="Select Proof of Address"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of Address Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfAddressCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfAddressCount', e.target.value)}
              placeholder="Proof of Address Count"
              min="0"
            />
          </div>

          
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">mandatory Documents</label>
            <Select
              options={documentsList}
              value={certificate.mandatoryDocuments}
              onChange={(selectedOption) => handleCertificateChange(index, 'mandatoryDocuments', selectedOption)}
              className="w-full"
              placeholder="Select Proof of mandatory Documents"
              isClearable
              isMulti
            />
          </div>

          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of mandatory Documents count </label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.mandatoryDocumentscount}
              onChange={(e) => handleCertificateChange(index, 'mandatoryDocumentscount', e.target.value)}
              placeholder="Proof of mandatory Documents count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700"> other Documents</label>
            <Select
              options={documentsList}
              value={certificate.otherDocuments}
              onChange={(selectedOption) => handleCertificateChange(index, 'otherDocuments', selectedOption)}
              className="w-full"
              placeholder="Select Proof of  other Documents"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Documents Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.otherDocumentsCount}
              onChange={(e) => handleCertificateChange(index, 'otherDocumentsCount', e.target.value)}
              placeholder="Proof of  other Documents Count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of age</label>
            <Select
              options={documentsList}
              value={certificate.ageProof}
              onChange={(selectedOption) => handleCertificateChange(index, 'ageProof', selectedOption)}
              className="w-full"
              placeholder="Select Proof of age Proof"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700"> age Proof Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.ageProofCount}
              onChange={(e) => handleCertificateChange(index, 'ageProofCount', e.target.value)}
              placeholder="Proof of age Proof Count"
              min="0"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">Proof of income</label>
            <Select
              options={documentsList}
              value={certificate.proofOfIncome}
              onChange={(selectedOption) => handleCertificateChange(index, 'proofOfIncome', selectedOption)}
              className="w-full"
              placeholder="Select Proof of income proof"
              isClearable
              isMulti
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700"> proof Of Income Count</label>
            <input
              type="number"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={certificate.proofOfIncomeCount}
              onChange={(e) => handleCertificateChange(index, 'proofOfIncomeCount', e.target.value)}
              placeholder="Proof of proof Of Income Count"
              min="0"
            />
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={addNewCertificate}
        className="w-full bg-green-600 text-white font-medium text-sm py-2 rounded-md hover:bg-green-700"
      >
        Add Another Certificate
      </button>

      <button
        type="button"
        onClick={addNewDepartment}
        className="w-full mt-4 bg-blue-600 text-white font-medium text-sm py-2 rounded-md hover:bg-blue-700"
      >
        Submit Department
      </button>
    </div>
  );
};

export default AddCertificateForm;
