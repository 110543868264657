import React, { useEffect, useState } from "react";
import SummaryApi from "../../common/Apis";
import { useNavigate } from "react-router-dom";

const ServicesList = ({ isClosed }) => {
  const [departments, setDepartments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch departments and certificates from server
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch(SummaryApi.addDepartment.url);
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Handle search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter departments and certificates based on search term
  const filteredDepartments = departments
    .map((department) => ({
      ...department,
      certificates: department.certificates.filter((certificate) =>
        certificate.name.toLowerCase().includes(searchTerm)
      ),
    }))
    .filter(
      (department) =>
        department.name.toLowerCase().includes(searchTerm) ||
        department.certificates.length > 0
    );

  const handlenavigate = (certificatename) => {
    navigate("/service", { state: { certificatename } });
  };

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate); // Set the clicked certificate
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setSelectedCertificate(null); // Clear selected certificate
    setShowModal(false); // Hide the modal
  };
  return (
    <div
      className={`flex-1 ${
        isClosed ? "ml-20" : "ml-50"
      }  transition-all duration-300`}
    >
      <h1 className="text-xl font-bold text-center mb-4">
        Services Available Online
      </h1>
      <input
        className="w-full p-2 my-4 rounded-md border border-black text-black focus:outline-none"
        type="text"
        placeholder="Search Here"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className=" overflow-y-auto h-screen scrollbar-none w-full">
        <div className="space-y-8 mt-4">
          {filteredDepartments.length > 0 ? (
            filteredDepartments.map(
              (department) =>
                department.certificates.length > 0 && (
                  <div key={department.id}>
                    <h2 className="text-lg font-bold">{department.name}</h2>
                    <hr className="bg-gray-800 h-1 rounded-lg mb-2" />

                    <div className="grid grid-cols-1 lg:grid-cols-2 p-3 gap-4">
                      {department.certificates.map((certificate) => (
                        // Certificate
                        <div
                          key={certificate._id}
                          className="p-3 bg-[#075985] text-white rounded-md cursor-pointer mb-2"
                          // onClick={() => handlenavigate(certificate.name)}
                          onClick={() => handleCertificateClick(certificate)}
                        >
                          <p className="relative group">{certificate.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )
            )
          ) : (
            <p className="text-white">No results found</p>
          )}
        </div>
      </div>

      {/* Modal for selected certificate */}
      {showModal && selectedCertificate && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-96 relative">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-black"
              onClick={handleCloseModal} // Close the modal
            >
              ✖
            </button>

            {/* Modal Content */}
            <h4 className="text-xl font-semibold mb-4">
              {selectedCertificate.name}
            </h4>
            <p className="text-sm text-gray-700 mb-3">
              Details for{" "}
              <span className="font-bold">{selectedCertificate.name}</span>.
            </p>
            {/* Price and GST Section */}
            <div className="flex justify-between items-center text-sm text-gray-700 font-medium mb-4">
              <span className="flex-1">Price:</span>
              <span className="text-right">₹{selectedCertificate.amount}</span>
            </div>
            <div className="flex justify-between items-center text-sm text-gray-700 font-medium mb-4">
              <span className="flex-1">GST (18%):</span>
              <span className="text-right">
                ₹{(selectedCertificate.amount * 0.18).toFixed(2)}
              </span>
            </div>

            {/* Total Amount */}
            <div className="flex justify-between items-center text-sm text-gray-900 font-bold mb-4">
              <span className="flex-1">Total Amount:</span>
              <span className="text-right">
                ₹
                {(
                  selectedCertificate.amount +
                  selectedCertificate.amount * 0.18
                ).toFixed(2)}
              </span>
            </div>

            <button
              onClick={() => handlenavigate(selectedCertificate.name)}
              className="w-full px-4 py-2 bg-[#075985] text-white rounded-md"
            >
              Apply Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesList;
